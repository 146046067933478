import { AbstractConnection, ConnectionStatus } from './index'
import type { IConnection, IDeviceEvent } from './index'

export enum ScaleEventType {
  CONFIG,
  CONNECTED,
  DISCONNECTED,
  MESSAGE
}

interface ScaleEvent extends IDeviceEvent<ScaleEventType> {
  type: ScaleEventType
  data: { code: string } | null
}

export interface IScaleConnection extends IConnection {}

class ScaleResponse {
  dataBuffer: number[] = []
}

class ScaleConnection extends AbstractConnection<ScaleEventType, ScaleEvent> implements IScaleConnection {
  private serialPort: SerialPort | undefined

  private reader: ReadableStreamDefaultReader<Uint8Array> | undefined
  private writer: WritableStreamDefaultWriter<Uint8Array> | undefined

  private databuffer: number[] = []

  private usbDataCallback(response: ScaleResponse) {
    const code = Buffer.from(response.dataBuffer).toString()
    this.eventListener({ type: ScaleEventType.MESSAGE, data: { code } }, this)
  }

  async open(): Promise<void> {
    this.eventListener({ type: ScaleEventType.CONFIG, data: null }, this)

    if (!this.serialOptions || !this.serialPortRequestOptions || !this.serialPortRequestOptions.filters?.length) {
      throw '找不到配置'
    }

    const usbProductIds = this.serialPortRequestOptions?.filters?.map((item) => item.usbProductId)
    const usbVendorIds = this.serialPortRequestOptions?.filters?.map((item) => item.usbVendorId)

    let p = (await navigator.serial.getPorts()).find((item) => {
      const info = item.getInfo()
      return usbProductIds?.includes(info.usbProductId) && usbVendorIds?.includes(info.usbVendorId)
    })

    if (!p) {
      p = await navigator.serial.requestPort(this.serialPortRequestOptions)
    }

    this.serialPort = p

    if (!this.serialPort) {
      throw '找不到设备'
    }

    this.status = ConnectionStatus.CONNECTED

    await this.serialPort.open(this.serialOptions)

    this.eventListener({ type: ScaleEventType.CONNECTED, data: null }, this)

    navigator.serial.addEventListener('disconnect', (e) => this.close())

    this.reader = this.serialPort.readable?.getReader()!
    this.writer = this.serialPort.writable?.getWriter()!

    while (this.serialPort.readable) {
      try {
        while (true) {
          const { value, done } = await this.reader.read()
          if (value?.length && value.length > 0) {
            this.databuffer.push(...value)

            this.usbDataCallback({ dataBuffer: this.databuffer })

            this.databuffer = []
          }

          if (done) {
            break
          }
        }
      } catch (error) {
      } finally {
        this.reader.releaseLock()
      }
    }
  }

  async close(): Promise<void> {
    try {
      // await this.reader?.cancel()

      // await this.serialPort?.close()

      this.status = ConnectionStatus.DISCONNECTED
      this.serialOptions = undefined
      this.serialPortRequestOptions = undefined

      // this.serialPort = undefined

      this.eventListener({ type: ScaleEventType.DISCONNECTED, data: null }, this)
    } catch (error) {}
  }
}

export const getScaleConnection = (eventListener: (event: ScaleEvent, instance: IScaleConnection) => any): IScaleConnection => {
  return new ScaleConnection(eventListener)
}
